.appbar {
  flex-direction: row !important;
  display: flex !important;
  background-color: white !important;
  .tabs {
    padding: 0px;
    box-shadow: none;
    flex-direction: row;
    justify-content: space-between;
    background-color: #4a5a9a;
    width: 80%;
    margin-right: 10px;
    color: white;
    height: 60px;
    .MuiTabs-root {
      min-height: 60px;
    }
    .MuiTabs-flexContainer {
      min-height: 60px;
    }
  }
  .right-filter {
    padding: 3px 10px;
  }
}

.tab-panel {
  padding: 16px 0px;
}
.MuiTabs-indicator {
  background: #f5af63 !important;
  height: 4px !important;
}
.card-content,
.data-list {
  padding: 2px !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 16px !important;
  border-radius: 6px;
  background: #fff;
}
.column-style {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.data-list {
  .date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #000 !important;
    border-bottom: 1px solid #cac6c6;
  }
}
.cardContent {
  padding: 0;
  &.bg {
    background-color: #ecaa6242;
  }
  .list-item {
    border-top: 1px solid #eee ad;
    &:last-child {
      border-bottom: 1px solid #eee ad;
    }
  }
  .deadline {
    color: red;
  }
  .date-icon {
    .right-icon {
      opacity: 0;
      transition: 0.5s linear all;
    }
    .title {
      font-size: 16px;
    }
    &:hover {
      .right-icon {
        opacity: 1;
        transition: 0.5s linear all;
      }
    }
    svg {
      margin: 0px 5px;
      font-size: 17px;
    }
  }
  .item-icon {
    min-width: 30px;
    svg {
      font-size: 18px;
    }
  }
  .right-icon {
    svg {
      font-size: 20px;
      margin: 0px 5px;
    }
  }
}
.MuiCardHeader-root {
  &:first-child {
    background: #44b8ff;
    padding: 3px 16px !important;
    color: white;
    .MuiTypography-body2 {
      font-weight: bold;
    }
    svg {
      color: white;
    }
  }
}
.head2 {
  background: #edaa62 !important;
}
.head3 {
  background: #28a745 !important;
}
