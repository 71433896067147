.drawer {
  h6 {
    padding: 10px 20px;
    background: #252d4d;
    color: white;
  }
  .drawer-form {
    max-width: 320px;
    padding: 20px;
  }
  .ml-20 {
    margin-left: 20px;
  }
}
