.header {
  background: #1a2038;
  justify-content: space-between;
  color: white;
  padding: 5px 16px !important;
  display: flex;
  align-items: center;
  button {
    color: white;
  }
}
.dialog-content {
  padding: 20px !important;
  background-color: #eee;
  min-height: 200px;
}
