// // Variables
$font-size-base: 0.95rem; // Assumes the browser default, typically `16px`

$layout-spacer: ($spacer * 2);

// // IP-BLOC Layout Components
$header-height: 55px;
$sidebar-width: 0px;
$app-sidebar-transition: cubic-bezier(0.685, 0.0473, 0.346, 1);
$font-size-xs: $font-size-base;
