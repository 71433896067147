.flexContainer {
  display: flex;
  margin-bottom: 8px;
  flex-direction: row;
}

.inputField {
  margin-right: 8px;
}

.sectionContainer {
  margin-bottom: 16px !important;
}

.view-patent {
  label {
    font-weight: 500;
  }
}
