.section-card {
  .bg {
    background-color: #3e6fa5 !important;
    color: #fff;
  }
  .patent-bg {
    background: #edaa62 !important;
  }
  .trademark-bg {
    background: #44b8ff !important;
  }
}
.MuiExpansionPanel-root.Mui-expanded {
  margin-top: 0 !important;
}
