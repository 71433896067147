//  Core
@import "../../../assets/core/base.scss";

.app-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #eee;
  // transition: all 0.5s $app-sidebar-transition;
}

.app-main {
  position: relative;
  min-height: 100vh;
}

.app-content {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;
  width: 100%;
  // transition: all 0.5s $app-sidebar-transition;

  &--inner {
    padding: 0.8rem;
    display: flex;
    flex: 1;
    &__wrapper {
      display: block;
      width: 100%;
    }
  }
}

.app-main-sidebar-static {
  display: flex;
  padding-top: 60px !important;
}

.app-content-footer-fixed {
  padding-bottom: 1rem;
  height: auto;
}
