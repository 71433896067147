.signin {
  background: #1a2038;
  &-card {
    max-width: 670px;
  }
  .submit-btn {
    font-size: 0.875rem;
    text-transform: none;
    font-weight: normal;
    border-radius: 5px;
    min-width: 110px;
    background-color: #004085;
    &:hover {
      background-color: #004085;
    }
  }
  h6 {
    max-width: 13rem;
    margin: 2rem 0;
  }
  .p-9 {
    padding: 2.25rem;
  }
  .p-8 {
    padding: 2rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .h-full {
    height: 100% !important;
  }

  h4 {
    padding: 1rem !important;
    text-align: center;
  }
  .buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
  .bg-light-gray {
    background: #eee;
  }
}
.h-full-screen {
  height: 100vh;
}
.w-full {
  width: 100%;
}
