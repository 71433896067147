.add-btn {
  position: fixed;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 60;
  padding: 17px;
  background: #1976d2;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  min-width: 0;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
