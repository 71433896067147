@mixin status {
  height: auto;
  min-height: 20px;
  font-weight: bold;
  font-size: 12px;
}
.materil-table {
  .goods {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiTable-root {
    overflow: hidden;
  }
  .MuiChip-label {
    padding: 0px 12px;
    // margin-top: 3px;
  }

  .MuiTableSortLabel-root:hover {
    color: #fff;
  }
  th:first-child {
    border-top-left-radius: 5px;
  }
  th:last-child {
    border-top-right-radius: 5px;
  }
  .yellow {
    background-color: #fb3;
    @include status();
  }
  .liteGreen {
    background-color: #2bbbad;
    @include status();
  }
  .green {
    background-color: #00c851;
    @include status();
  }
  .blue {
    background-color: #28619e;
    @include status();
  }
  .purple {
    background-color: #a6c;
    @include status();
  }
  .liteBlue {
    background-color: #4285f4;
    @include status();
  }
  .red {
    background-color: #ff3547;
    @include status();
  }
  .skyblue {
    background-color: #33b5e5;
    @include status();
  }
  .grey {
    background-color: #e0e0e0;
    @include status();
  }
  .black {
    background-color: #000;
    @include status();
  }
}
